<template>
  <basic-page-card
    pageName="Manage Subscriptions"
    iconClass="fa fa-bell fa-lg"
  >
    <div>
      <b-tabs v-model="tabIndex">
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold mb-0">FIN Query</h6>
          </template>

          <v-wait for="loadingSubscriptions">
            <template slot="waiting">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>

            <b-row class="justify-content-end mb-4">
              <b-col sm="4">
                <b-form-group label="Search" :label-cols="3" :horizontal="true">
                  <b-form-input v-model="searchText"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              striped
              hover
              :fields="tableFields"
              :filter="searchText"
              :items="finQuerySubscriptions"
              small
              responsive
              show-empty
            >
              <template v-slot:empty="scope">
                <div class="text-center p-2">
                  <h6>There are no subscriptions to show.</h6>
                </div>
              </template>
              <template v-slot:emptyfiltered="scope">
                <div class="text-center p-2">
                  <h6>No matching subscriptions found.</h6>
                </div>
              </template>
              <template v-slot:cell(schedule)="data">
                {{ data.item.schedule | capitalize({ onlyFirstLetter: true }) }}
              </template>
              <template v-slot:cell(delivery_mode)="data">
                {{ deliveryMode(data.item.delivery_mode) }}
              </template>
              <template v-slot:cell(delivery_format)="data">
                {{ deliveryFormat(data.item.delivery_format) }}
              </template>
              <template v-slot:cell(actions)="data">
                <b-button
                  size="sm"
                  variant="primary"
                  class="mx-2"
                  @click="editSubscription(data.item)"
                >
                  EDIT
                </b-button>
                <b-button
                  size="sm"
                  variant="secondary"
                  @click="deleteSubscription(data.item.id)"
                >
                  DELETE
                </b-button>
              </template>
            </b-table>
          </v-wait>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal
      id="editSubscription"
      title="Edit Subscription"
      size="md"
      okTitle="Update"
      hide-close
      @ok="updateSubscription"
    >
      <div class="row">
        <div class="col-sm-12">
          <v-wait for="fetchingSubscription">
            <template slot="waiting">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>
          </v-wait>
          <div class="row">
            <div class="col-sm-4">
              <div class="">
                Query :
              </div>
            </div>
            <div class="col-sm-7 pl-0">
              <b-form-select
                v-model="selectedSubscription.advanced_query_id"
                :options="queries"
                text-field="name"
                value-field="id"
                class="mt-1 form-control mb-2"
                :disabled="true"
              ></b-form-select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <div class="">
                Report :
              </div>
            </div>
            <div class="col-sm-7 pl-0">
              <b-form-select
                v-model="selectedSubscription.report_id"
                :options="reports"
                text-field="name"
                value-field="id"
                class="mt-1 form-control mb-2"
                :disabled="true"
              ></b-form-select>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-sm-4">
              <div class="price-info">
                Delivery Interval :
              </div>
            </div>
            <div class="col-sm-7 pl-0">
              <b-form-select
                v-model="selectedSubscription.schedule"
                :options="intervalOptions"
                class="mt-1 form-control mb-2"
              ></b-form-select>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-sm-4">
              <div class="price-info">
                Delivery Mode :
              </div>
            </div>
            <div class="col-sm-7 pl-0">
              <b-form-select
                v-model="selectedSubscription.delivery_mode"
                :options="deliveryOptions"
                class="mt-1 form-control mb-2"
              ></b-form-select>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-sm-4">
              <div class="price-info">
                Report Format :
              </div>
            </div>
            <div class="col-sm-7 pl-0">
              <b-form-select
                v-model="selectedSubscription.delivery_format"
                :options="formatOptions"
                class="mt-1 form-control mb-2"
              ></b-form-select>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </basic-page-card>
</template>

<script>
// api
import userAPI from '@/api/finapps/user'
import subscriptionAPI from '@/api/finapps/subscription'
// utilities
import deepClone from '@/utilities.js'

export default {
  name: 'Subscription',
  components: {},
  data() {
    return {
      tabIndex: 0,
      queries: [],
      reports: [],
      subscriptions: [],
      searchText: null,
      selectedSubscription: {},
      tableFields: [
        { key: 'query_name', label: 'Query', sortable: true },
        { key: 'report_name', label: 'Report', sortable: true },
        { key: 'schedule', label: 'Interval', sortable: true },
        { key: 'delivery_mode', label: 'Delivery Mode', sortable: true },
        { key: 'delivery_format', label: 'Report Format', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      intervalOptions: [
        { text: 'Monthly', value: 'monthly' },
        { text: 'Quarterly', value: 'quarterly' },
        { text: 'Annual', value: 'annual' }
      ],
      deliveryOptions: [{ text: 'Application Download', value: 'app_link' }],
      formatOptions: [
        { text: 'CSV', value: 'csv' },
        { text: 'Excel', value: 'xlsx' }
      ]
    }
  },
  created() {
    this.loadInitData()
  },
  computed: {
    finQuerySubscriptions() {
      return this.subscriptions.filter(
        s => s.subscription_source === 'fin_query'
      )
    }
  },
  methods: {
    deliveryMode(mode) {
      return this.deliveryOptions.find(opt => opt.value === mode).text
    },
    deliveryFormat(format) {
      return this.formatOptions.find(opt => opt.value === format).text
    },
    loadInitData() {
      this.$wait.start('loadingSubscriptions')

      userAPI.subscriptions().then(res => {
        this.subscriptions = res.subscriptions
        this.queries = res.advanced_queries
        this.reports = res.reports
        this.$wait.end('loadingSubscriptions')
      })
    },
    editSubscription(subscription) {
      this.selectedSubscription = deepClone(subscription)
      this.$bvModal.show('editSubscription')
    },
    updateSubscription() {
      subscriptionAPI
        .update(this.selectedSubscription.id, {
          schedule: this.selectedSubscription.schedule,
          delivery_format: this.selectedSubscription.delivery_format,
          delivery_mode: this.selectedSubscription.delivery_mode
        })
        .then(() => {
          let subscriptionIndex = this.subscriptions.findIndex(
            s => s.id === this.selectedSubscription.id
          )
          this.subscriptions.splice(
            subscriptionIndex,
            1,
            this.selectedSubscription
          )

          this.$toasted.show('Successfully updated subscription preferences.', {
            icon: 'user-circle',
            type: 'success'
          })
        })
    },
    deleteSubscription(subscriptionID) {
      return this.$dialog
        .confirm(
          {
            title: 'Alert',
            body: 'Are you sure, you want to delete the subscription?'
          },
          {
            okText: 'Yes',
            cancelText: 'No'
          }
        )
        .then(() => {
          subscriptionAPI.delete(subscriptionID).then(() => {
            let subscriptionIndex = this.subscriptions.findIndex(
              sub => sub.id === subscriptionID
            )
            this.subscriptions.splice(subscriptionIndex, 1)

            this.$toasted.show('Successfully unsubscribed.', {
              icon: 'user-circle',
              type: 'success'
            })
          })
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped></style>
